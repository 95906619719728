import { HTTP } from './httpCommon';

export const httpSearch = {

    ////////////////////////////////////////////
    ///PLACESOLD
    ////////////////////////////////////////////

    //Search PlaceSold
    getPlaceSold(query) {
        return HTTP.post('PlaceSold/Search', query)
            
    },

}