<template>
    <v-dialog v-model="dialog"
              persistent
              max-width="600">
        <v-card>
            <v-toolbar dense>
                <v-toolbar-title>Select Place Sold</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click.native="closeView()">
                    <v-icon color="red">fas fa-times</v-icon>
                </v-btn>
            </v-toolbar>
           
            <v-container grid-list-md>
                <v-layout wrap>
                    <Search v-bind:addButtonMessage="'Add Place Sold'"
                            v-on:add="createPlaceSold($event)"
                            v-on:gSearch="updateSearch($event)"></Search>

                    <v-flex xs12>
                        <v-divider></v-divider>
                    </v-flex>

                    <v-flex xs12>
                        <v-list rounded>
                            <v-list-item-group v-model="selectedPlaceSold"
                                               color="primary">
                                <v-list-item v-for="(item, i) in items"
                                             :key="i">
                                    <template>
                                        <v-list-item-content>
                                            <v-list-item-title v-html="item.retailerName"></v-list-item-title>
                                            <v-list-item-subtitle v-html="item.country"></v-list-item-subtitle>
                                        </v-list-item-content>
                                    </template>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-card>

        <AddPlaceSold v-if="addPlaceSold"
                      v-on:itemShow="addPlaceSold = $event"></AddPlaceSold>

    </v-dialog>
</template>

<script>
    import { returnAvailablePlaceSold, isNullEmptyOrUndefined } from '../../javascript/globalFunctions'
    import { httpSearch } from '../../api/httpSearch'

    export default {
        components: {
            Search: () => import('../search/searchWithAddButton.vue'),
            AddPlaceSold: () => import('./addPlaceSold.vue')
        },
        data() {
            return {
                dialog: true,
                items: [],
                searchItems: [],
                addPlaceSold: false,
                selectedPlaceSold: '',
                isSearchSent: false,
            }
        },
        methods: {
            closeView: function () {
              this.$emit('placeSoldShow', false)
            },
            searchResult: async function (search) {
                let searchItems = []

                if (this.isSearchStringOverThreeChars(search) && !this.isSearchSent) {
                    let query = this.buildQueryForSearch(search)

                    searchItems = await httpSearch.getPlaceSold(query)
                    this.searchItems = searchItems.data

                    //Mark isSearchSent as true
                    this.isSearchSent = true
                }

                return this.searchItems
            },
            buildQueryForSearch: function (search) {
                let query = {
                    retailerName: '',
                    locationName: '',
                    country: ''
                }

                query.retailerName = search
                query.locationName = search
                query.country = 'Any'

                return query
            },
            isSearchStringOverThreeChars: function (search) {
                if (search.length >= 3) {
                    return true
                }
                return false
            },
            createPlaceSold: function (add) {
                this.addPlaceSold = add
            },
            placeSoldSelected: function () {
                this.$emit('selectedPlaceSold', this.selectedPlaceSold)
                this.closeView()
            },
            getSearchItems: async function (search) {
                const itemsMinusDeleted = await this.searchResult(search)
                this.items = []

                if (!isNullEmptyOrUndefined(itemsMinusDeleted)) {

                    //Remove Transactions that does not meet search criteria 
                    for (let i = 0; i < itemsMinusDeleted.length; i++) {
                        if (itemsMinusDeleted[i].retailerName.toLowerCase().indexOf(search.toLowerCase()) !== -1) {
                            this.items.push(itemsMinusDeleted[i])
                        }
                    }
                }
            },
            updateSearch: async function (search) {

                //Check if Search is empty  
                if (isNullEmptyOrUndefined(search)) {
                    //Get All Items from item
                    this.items = returnAvailablePlaceSold()
                    this.isSearchSent = false
                    return
                }

                await this.getSearchItems(search)
            },
        },
        watch: {
            "selectedPlaceSold": function () {
                //this.selectedPlaceSold = this.items[this.selectedPlaceSold].retailerName
                this.selectedPlaceSold = this.items[this.selectedPlaceSold]

                this.placeSoldSelected()
            }
        },
        beforeMount() {
            this.items = returnAvailablePlaceSold()
        }
    }
</script>